import styled from 'styled-components';
import { labelColor, placeholderBackgroundColor, buttonHoverBackgroundColor, headerBackgroundColor } from '../../theme/theme';

export const Wrapper = styled.div<any>`
  cursor: pointer;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
  margin-left: 0.5rem;
  border: 1px solid ${labelColor};
  border-radius: 20px;
  padding: 0 0.4rem;
  transition: all 1s;
  flex-grow: 1;

  .list {
    color: ${labelColor};
    flex-grow: 1;
    padding: 0 0.2rem;
    position: relative;

    .caret {
      margin-left: 0.5rem;
      color: ${labelColor};
    }
  }

  ul {
      position: absolute;
      top: 60%;
      left: 0px;
      width: 100%;
      padding: 0.25rem 0;
      transition: all 0.3s;
      background-color: ${headerBackgroundColor};
      border-radius: 20px;

      li {
        list-style: none;
        padding: 0.1rem;
        color: ${labelColor};
        font-weight: 400;
        font-size: 0.9rem;
        text-align: center;
        background: ${placeholderBackgroundColor};
        border-radius: 20px;
        margin: 0.5rem 0;

        /* hover color where device has pointer */
        @media(hover: hover) and (pointer: fine) {
          transition: all 0.15s;
          &:hover {
            background-color: ${buttonHoverBackgroundColor};
          }
        }
      }
    }

  .toggle {
    height: 32px;
    width: 32px;
    position: relative;

    button {
      border: none;
      background: none;
      margin-right: 0;
    }
  
    svg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      padding: 0.4rem;

      path {
        stroke: ${labelColor};
        stroke-dasharray: 1000;
        stroke-width: 75px;
      }

      @keyframes shine {
        from { opacity: 1; }
        to { opacity: 0; }
      }

      @keyframes sun {
        from { stroke-dashoffset: 200; }
        to { stroke-dashoffset: 1000; }
      }

      @keyframes moon {
        from { stroke-dashoffset: 1000; }
        to { stroke-dashoffset: 200; }
      }

      .shine {
      ${props => (props.theme.count === 0 || props.theme.transitionTheme === 0)
    ? `opacity: ${props.theme.mode === 'dark' ? 0 : 1};`
    : `
        animation-name: shine;
        animation-duration: 0.4s;
        animation-timing-function: ease-out;
        animation-delay: ${props.theme.mode === 'dark' ? `0.3s` : `0.1s`};
        animation-direction: ${props.theme.mode === 'dark' ? `normal` : `reverse`};
        animation-fill-mode: forwards;
        opacity: ${props.theme.mode === 'dark' ? 1 : 0};`}
      }

      .sun {
      ${props => (props.theme.count === 0 || props.theme.transitionTheme === 0)
    ? `opacity: ${props.theme.mode === 'dark' ? 0 : 1};`
    : `
        animation-name: sun;
        animation-duration: 0.7s;
        animation-timing-function: ease-out;
        animation-direction: ${props.theme.mode === 'dark' ? `normal` : `reverse`};
        animation-fill-mode: forwards;` }
      }

      .moon {
      ${props => (props.theme.count === 0 || props.theme.transitionTheme === 0)
    ? `opacity: ${props.theme.mode === 'dark' ? 1 : 0};`
    : `
        animation-name: moon;
        animation-duration: 1s;
        animation-timing-function: ease-in;
        animation-direction: ${props.theme.mode === 'dark' ? `normal` : `reverse`};
        animation-fill-mode: forwards;` }
      }
    }
  }
`;

export default Wrapper;