import React from 'react';
import styled from 'styled-components';
import { buttonTextColor, buttonHoverTextColor, buttonActiveBackgroundColor, buttonSelectedBackgroundColor } from '../../theme/theme';
import { containerWidths } from '../../constants';
import { ButtonProps } from '../../types';

const StyledButton = styled.button<any>`
  border: none;
  padding: 0.45em 1.20em;
  border-radius: 50px;
  font-size: 1rem;
  background: none;
  cursor: pointer;
  color: ${buttonTextColor};
  font-weight: 600;
  outline: none;
  transition: all 0.15s;
  background-color: ${props => props.selected === true ? buttonSelectedBackgroundColor : `none`};

  @media(max-width: ${containerWidths.mobile}px) {
    font-size: 0.9rem;
    padding: 0.45em 1.20em;
  }
  @media(hover: hover) and (pointer: fine) {
    &:hover {
      color: ${buttonHoverTextColor};
      border-color: ${buttonHoverTextColor};
      background-color: ${buttonActiveBackgroundColor};
    }
  }

  &:active {
    background: ${buttonActiveBackgroundColor};
  }
 `;

export const ButtonPrimary: React.FC<ButtonProps> = (props: any) => {

  return (
    <StyledButton selected={props.selected}>
      {props.label}
    </StyledButton>
  )
}