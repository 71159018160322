import React from "react";

export const MenuContext: React.Context<any> = React.createContext({
  sideMenuOpen: false,
  toggleSideMenu: () => { },
  themeListOpen: false,
});

export const useMenu = () => React.useContext(MenuContext);

export class ContextMenu extends React.Component<any, any> {

  public state = {
    sideMenuOpen: false,
    themeListOpen: false,
  };

  public toggleSideMenu = async () => {
    this.setState({
      sideMenuOpen: !this.state.sideMenuOpen,
    });
  }

  public toggleThemeList = async (val) => {
    this.setState({
      themeListOpen: val,
    });
  }

  public render () {

    return (
      <MenuContext.Provider value={{
        sideMenuOpen: this.state.sideMenuOpen,
        themeListOpen: this.state.themeListOpen,
        toggleSideMenu: this.toggleSideMenu,
        toggleThemeList: this.toggleThemeList,
      }}>
        {this.props.children}
      </MenuContext.Provider>
    );
  }
}

export default ContextMenu;

