import React from 'react';
import styled from 'styled-components';
import { buttonTextColor, buttonHoverTextColor, buttonActiveBackgroundColor } from '../../theme/theme';
import { ButtonProps } from '../../types';

const StyledButton = styled.button<any>`
  border: none;
  padding: ${props => props.haveBorder === false ? `0` : `0.45em 1.2em`};
  border-radius: 50px;
  font-size: 1rem;
  background: none;
  border-width: 1px;
  border-color: ${buttonTextColor};
  border: ${props => props.haveBorder === false ? `none !important` : `1px solid`};
  cursor: pointer;
  color: ${buttonTextColor};
  font-weight: 550;
  outline: none;
  transition: all 0.15s;

  @media(hover: hover) and (pointer: fine) {
    &:hover {
      color: ${buttonHoverTextColor};
      border-color: ${buttonHoverTextColor};
    }
  }

  &:active {
    background: ${buttonActiveBackgroundColor};
  }
`;

export const Button: React.FC<ButtonProps> = (props) => {

  return (
    <StyledButton
      haveBorder={!(props.border === false)}
      onClick={() => { props.onClick !== undefined && props.onClick(); }}
    >
      {props.icon !== undefined && props.icon}
      {props.label}
    </StyledButton>
  )
}