import React from 'react';
import { NotifierContextState } from '../../types';
import Wrapper from './Wrapper';

const defaultStatus = 'closed';
const defaultMessage = 'default';

export const NotifierContext: React.Context<NotifierContextState> = React.createContext({
  notifier: defaultMessage,
  status: defaultStatus,
  call: () => { }
});

export const useNotifier = () => React.useContext(NotifierContext);

export class NotifierManager extends React.Component<any> {
  state = {
    status: defaultStatus,
    notifier: defaultMessage
  };

  render () {

    const call = (status: string, notifier: string): void => {
      this.setState({
        status: status,
        notifier: notifier
      });
    }

    return (
      <NotifierContext.Provider value={{
        status: this.state.status,
        notifier: this.state.notifier,
        call: call
      }}>
        {this.props.children}
      </NotifierContext.Provider >
    );
  }
}

export const Notifier: React.FC = () => {

  const notifier = useNotifier();

  let statusClass;

  switch (notifier.status) {

    case 'open':
      statusClass = 'notifier-open';

      setTimeout(() => {
        notifier.call('closing', notifier.notifier);
      }, 1250);
      break;

    case 'closing':
      statusClass = 'notifier-close';
      setTimeout(() => {
        notifier.call('closed', '');
      }, 500);
      break;

    default:
      statusClass = '';
  }

  return (
    <Wrapper className={statusClass}>
      <div>
        <h2>{notifier.notifier}</h2>
      </div>
    </Wrapper>

  );
}

export default Notifier;
