export const API_URL = process.env.NODE_ENV === 'development' ? '' : 'https://jkrb.site';

export const MENU_LINKS = [];
// [{
//   'label': 'Home',
//   'path': '/',
//   'active': true,
// }
// ];

export const containerWidths = {
  mobile: 600,
  tablet: 850,
  desktop: 1075
}

export const containerWidth = {
  mobile: containerWidths.mobile + 'px',
  tablet: containerWidths.tablet + '0px',
  desktop: containerWidths.desktop + 'px'
}

export const containerPadding = {
  mobile: '0.75rem',
  tablet: '2rem',
  desktop: '2rem'
}
