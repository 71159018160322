import styled from 'styled-components';
import { containerWidths } from '../../constants';
import { textColor, labelColor, buttonHoverBackgroundColor } from '../../theme/theme';


/* Logo
 * wrapper element for JKRB logo svg
 * used in header and side menu
 */
export const Logo = styled.div`
  position: relative;

  .logo svg {
    height: 36px;
    margin-left: 0.2rem;
    fill: ${textColor};
    
    @media(min-width: ${containerWidths.mobile + 1}px) {
      height: 44px;
      margin-left: 0rem;
    }
  }
  .icon {
    position: absolute;
    bottom: -2px;
    right: -34px;
    width: 28px;
    height: 28px;
    opacity: 0.75;
    background: ${buttonHoverBackgroundColor};
    border-radius: 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    svg {
      height: 100%;
      width: 100%;
      padding: 0.28rem;

      path: {
        fill: ${labelColor};
      }
      fill: ${labelColor};
    }
  }
`;
