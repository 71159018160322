import styled from 'styled-components';
import { containerWidths } from './constants';
import { backgroundColor, textColor, buttonHoverTextColor, buttonTextColor } from './theme/theme';

/* Wrapper - main div of App
 * high level elements such as links and headers are styled here.
 */
export const Wrapper = styled.div<any>`

  /* default background color */
  background-color: ${backgroundColor};

  /* default text color */
  color: ${textColor};

  /* app consumes window height */
  min-height: 100vh;

  /* default text alignment */
  text-align: left;

  /* theme transition support */
  transition: background-color 0.35s;

  /* default h1 styles with title extension */
  h1 {
    font-size: 1.7rem;
    word-wrap: break-word;
    
    &.title {
      align-items: flex-end;
      display: flex;
      font-size: 2.25rem;
      line-height: 2.7rem;
      margin-top: 0rem;
      
      @media(max-width: 570px) {
        min-height: 2.5em;
      }
      @media(min-width: ${containerWidths.mobile + 1}px) {
        font-size: 3rem;
        line-height: 3rem;
      }
      @media(min-width: ${containerWidths.tablet + 1}px) {
        font-size: 3.2rem;
        line-height: 3.2rem;
      }
    }
    @media(min-width: ${containerWidths.mobile + 1}px) {
      font-size: 2.3rem;
    }
    @media(min-width: ${containerWidths.tablet + 1}px) {
      font-size: 3rem;
    }
  }

  /* default h2 styles with subtitle extension */
  h2 {
    font-size: 1.3rem;
    line-height: 1.75rem;
    word-wrap: break-word;

    .subtitle {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
    @media(min-width: ${containerWidths.mobile + 1}px) {
      font-size: 1.4rem;
    }
    @media(min-width: ${containerWidths.tablet + 1}px) {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
  
  /* default h3 styles */
  h3 {
    font-size: 1.1rem;
    word-wrap: break-word;

    @media(min-width: ${containerWidths.mobile + 1}px) {
      font-size: 1.2rem;
    }
    @media(min-width: ${containerWidths.tablet + 1}px) {
      font-size: 1.25rem;
    }
  }

   /* default h4 styles */
   h4 {
    word-wrap: break-word;
  }

  /* default h5 styles */
  h5 {
    word-wrap: break-word;
  }

  /* default link style */
  a {
    color: ${buttonTextColor};
    @media(hover: hover) and (pointer: fine) {
      transition: all 0.15s;
      &:hover {
        color: ${buttonHoverTextColor};
      }
    }
  }

  /* default paragraph and list style */
  p, li,
  .blog span {
    font-size: 0.96rem;
    line-height: 1.5em;
    font-weight: 600;
    word-wrap: break-word;
    margin-left: 0;
    margin-right: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  /* convenience class for mobile content switching */
  .hide-mobile {
    @media(max-width: ${containerWidths.mobile}px) {
      display: none;
    }
    @media(min-width: ${containerWidths.mobile + 1}px) {
      display: inline;
    }
  }

  .show-mobile {
    @media(max-width: ${containerWidths.mobile}px) {
      display: inline;
    }
    @media(min-width: ${containerWidths.mobile + 1}px) {
      display: none;
    }
  }

  /* default button styles */
  button {
    background-color: none;
    cursor: pointer;
    font-weight: 550;
    outline: none;
    transition: all 0.15s;
    font-size: 1rem;
  }

  /* default svg icon colors */
  svg.icon {
    fill:${textColor};
    path {
      fill:${textColor};
    }
  }
`;