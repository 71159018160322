import React from 'react';
import { Landing } from './Landing';
import { Founders } from './Founders';
import { Contact } from './Contact';
import { NotifierManager, Notifier } from '../../library/Notifier';
import { Helmet } from "react-helmet";

const Home: React.FC = () => {

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://jkrb.io/" />
      </Helmet>
      <Landing />
      <Founders />
      <NotifierManager>
        <Contact />
        <Notifier />
      </NotifierManager>
    </>
  );
}

export default Home;