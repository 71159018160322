import React from 'react';
import styled from 'styled-components';
import { Section, SubSection } from '../../library/StyledComponents';
import { Tooltip } from 'react-tooltip';

const StyledCode = styled.div`
  display: flex;
  flex: 1;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
  word-wrap: break-word;
  position: relative;
  width: 100%;
  padding-top: 50px;
`;

export const Code: React.FC = () => {

  return (
    <Section>
      <SubSection>
        <StyledCode>
          <div>
            <h1 className='title'>
              JKRB Code
            </h1>
            <h2 className='subtitle'>
              A list of our open source repositories.
            </h2>
          </div>
        </StyledCode>
      </SubSection>
      <Tooltip />
    </Section>
  );
}

export default Code;