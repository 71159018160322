import React from 'react';
import { Wrapper } from './Wrapper';
import { Link } from './Link';
import { CirclePrimary } from '../../library/StyledComponents';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMenu } from '../../library/MenuContext';
import { MENU_LINKS } from '../../constants';

export const SideMenu = () => {

  // get menu state from context
  const menu = useMenu();

  return (
    <Wrapper menuOpen={menu.sideMenuOpen}>
      <div className='content'>
        <div className='header'>
          <CirclePrimary onClick={() => menu.toggleSideMenu()}>
            <FontAwesomeIcon icon={faChevronRight} transform="grow-2" style={{ marginLeft: 2 }} />
          </CirclePrimary>
        </div>

        <h5>Site</h5>
        {MENU_LINKS.map((item, index) => {
          if (item.active) {
            return (
              <React.Fragment key={index}>
                <Link to={item.path} label={item.label} key={index} />
              </React.Fragment>
            );
          } else {
            return <React.Fragment key={index} />
          }
        })}


        <h5>Contact</h5>
        <p>
          <a href="mailto:contact@jkrb.io"
            target="_blank"
            rel="noopener noreferrer">
            contact@jkrb.io
            </a>
        </p>
      </div>
    </Wrapper>
  );
}

export default SideMenu;