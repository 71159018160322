import React from "react";
import { ThemeContextState } from '../../types';
import { useCookies } from 'react-cookie';

export const ManageThemeContext: React.Context<ThemeContextState> = React.createContext({
  count: 0,
  mode: 'light',
  toggleTheme: (str) => { },
  transitionTheme: 1,
  activeLightTheme: 'light',
});

export const useTheme = () => React.useContext(ManageThemeContext);

export const ThemeManager: React.FC<any> = ({ children }: any) => {

  const [cookieTheme, setCookieTheme] = useCookies<any>(['theme']);

  const _playTransition = (_current: string, _new: string) => {
    let play = (_new === 'dark' || _current === 'dark') ? 1 : 0;
    return play;
  }

  const currentTheme = () => {
    if (cookieTheme.mode === undefined) {
      return 'light';
    } else {
      return cookieTheme.mode;
    }
  }

  const [themeState, setThemeState] = React.useState({
    count: 0,
    mode: currentTheme(),
    activeLightTheme: currentTheme() === 'dark' ? 'light' : currentTheme(),
  });

  const { count, mode, activeLightTheme } = themeState;

  const [transitionState, setTransitionState] = React.useState({
    transitionTheme: (mode === 'light' || mode === 'dark') ? 1 : 0
  });

  const toggleTheme = (newTheme: string): void => {

    if (newTheme === 'auto') {
      newTheme = (mode === 'dark' ? activeLightTheme : `dark`);
    }

    let newCount = count + 1;

    setCookieTheme('mode', newTheme, { path: '/' });

    if (_playTransition(mode, newTheme)) {
      setTransitionState({
        transitionTheme: 1,
      });
      setTimeout(() => {
        setTransitionState({
          transitionTheme: 0
        });
      }, 800);
    }

    setThemeState({
      mode: newTheme,
      count: newCount,
      activeLightTheme: newTheme !== 'dark' ? newTheme : activeLightTheme,
    });
  }

  return (
    <ManageThemeContext.Provider value={{
      count: count,
      mode: mode,
      toggleTheme: toggleTheme,
      transitionTheme: transitionState.transitionTheme,
      activeLightTheme: activeLightTheme,
    }}>
      {children}
    </ManageThemeContext.Provider>
  );
}