import React from 'react';
import { textColor, labelColor } from '../../../theme/theme';
import { containerWidths } from '../../../constants';
import styled from 'styled-components';
import { CMPCLogo, EthereumLogo, Web3Logo, MediumLogo, EmberrLogo } from '../../../library/SVG';
import { Button } from '../../../library/Button';
import { Link } from 'react-scroll';
import { Section, SubSection, ButtonRow } from '../../../library/StyledComponents';
import Typed from 'react-typed';
import { Tooltip } from 'react-tooltip';

const Summary = styled.div`
  display: flex;
  flex: 1;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  flex-flow: row wrap;
  padding-top: 50px;

  h1 {
    transition: all 0.2s;
    .typed-cursor {
      opacity: 1;
      animation: blink 1s infinite;
      font-weight: 300;
      margin: 0;
      color: ${textColor};
    }
    
  @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

const Collaborations = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-align: center;
  padding-bottom: 3rem;
  margin-top: 2rem;
  @media(min-width: ${containerWidths.mobile + 1}px) {
    margin-top: 0;
  }
  
  h3 {
    color: ${labelColor};
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    height: auto;
    margin: 1.5rem 0 1rem 0;

    @media(min-width: ${containerWidths.mobile + 1}px) {
      margin: 0rem 0 1rem 0;
    }

    > div {
      margin: 0.5rem 0.75rem;
      flex: 0;
      @media(min-width: ${containerWidths.mobile + 1}px) {
        margin: 0.75rem 1.25rem;
      }
      @media(min-width: ${containerWidths.tablet + 1}px) {
        margin: 1rem 1.5rem;
      }

      svg {
        fill: ${textColor};
        height: 35px;
        @media(min-width: ${containerWidths.mobile + 1}px) {
          height: 45px;
        }
        @media(min-width: ${containerWidths.tablet + 1}px) {
          height: 50px;
        }
        path {
          fill: ${textColor};
        }
      }
    }
  }`;

export const Landing: React.FC = () => {

  return (
    <Section>
      <SubSection>
        <Summary>
          <h1 className='title'>
            <Typed
              strings={[
                'Collaborative Development',
                'Open Governance',
                'Tool &amp; Service Creation',
                'Inspiring Progress']}
              typeSpeed={53}
              backSpeed={35}
              startDelay={400}
              backDelay={3500}
              showCursor={true}
              loop />
          </h1>
          <h2 className='subtitle'>
            JKRB stands at the forefront of blockchain development, specialising in creating tools and applications that drive value. Our focus is on harnessing the power of innovative technology to support developers in building solutions that enhance and expand the capabilities of decentralised platforms. 
          </h2>
          <h2>
            Our commitment is to foster growth and innovation in the blockchain space, making JKRB a pivotal player in the development of technologies that empower users and developers alike.
          </h2>
          <ButtonRow>
            <Link to="founders" smooth="easeOutBack" duration={750}>
              <Button label="Founders" />
            </Link>
            <Link to="contactUs" smooth="easeOutBack" duration={1750}>
              <Button label="Get in Touch" />
            </Link>
          </ButtonRow>
        </Summary>
        <Collaborations>
          <h3>Collaborations</h3>
          <div>
            <div data-tip="Medium">
              <MediumLogo />
            </div>

            <div data-tip="CMP Construct">
              <a
                href="https://cmpconstruct.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CMPCLogo />
              </a>
            </div>

            <div data-tip="Emberr">
              <a
                href="https://emberr.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <EmberrLogo />
              </a>
            </div>

            <div data-tip="Ethereum Foundation">
              <EthereumLogo />
            </div>

            <div data-tip="Web3 Foundation" >
              <Web3Logo />
            </div>
          </div>
        </Collaborations>
      </SubSection>
      <Tooltip />
    </Section>
  );
}

export default Landing;
