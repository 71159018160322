import styled from 'styled-components';
import { labelColor, sideMenuBackgroundColor, borderColor } from '../../theme/theme';

export const Wrapper = styled.div<any>`
  position: fixed;
  top: 0px;
  right: ${props => props.menuOpen === true ? `0px` : `-300px`};
  width: 300px;
  height: 100vh;
  z-index: 4;
  background: ${sideMenuBackgroundColor};
  transition: all 0.2s;
  animation-fill-mode: forwards;
  border-left: 1px solid ${borderColor};
  border-radius: 5px;
  padding: 0.5rem 0 0.5rem 0;

  .content {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    align-content: flex-start;

    h4 {
      margin: 0.25rem 1rem 1rem 1rem;
    }

    h5 {
      margin-top: 2rem;
      color: ${labelColor};
    }
    p { 
      margin: 0 0 1rem 0;
    }
    > div {
      padding: 0 0.5rem;
      
      &.header {
        padding: 1rem 0.5rem 0 0.5rem;
      }
    }
  }
`;

export default Wrapper;