import React from 'react';
import { useMenu } from '../../library/MenuContext';
import { MenuLinkProps } from '../../types';
import { Link as RouterLink } from 'react-router-dom';

export const Link: React.FC<MenuLinkProps> = ({ label, to }) => {

  // get menu state from context
  const menu = useMenu();

  return (
    <h4>
      <RouterLink to={to} onClick={() => menu.toggleSideMenu()}>{label}</RouterLink>
    </h4>
  );

}

export default Link;