import React from 'react';
import ContentLoader from 'react-content-loader';
import { AllThemes } from '../../theme/all';
import { useTheme } from '../../library/ThemeManager';

const ArticleLoader = (props: any) => {

  const { mode } = useTheme();

  const contentLoaderForegroundColors = AllThemes.preloader.foregrounnd;
  const contentLoaderBackgroundColors = AllThemes.preloader.background;

  return (
    <div style={{ padding: '1rem', width: '100%', maxWidth: '470px' }}>
      <ContentLoader
        speed={2}
        width='100%'
        height={124}
        viewBox="0 0 476 124"
        backgroundColor={contentLoaderForegroundColors[mode]}
        foregroundColor={contentLoaderBackgroundColors[mode]}
        {...props}
      >
        <rect x="0" y="8" rx="3" ry="3" width="250" height="6" />
        <rect x="0" y="26" rx="3" ry="3" width="80" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="470" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="470" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
      </ContentLoader>
    </div>
  );
}

export default ArticleLoader;