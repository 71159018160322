import theme from 'styled-theming';
import { AllThemes } from '../theme/all';

/* Aggregates all theme configurations and serves the currently
 * active theme via the theming context.
 */

/* Background Colors */

// default background color of the page
export const backgroundColor: theme.ThemeSet = theme('mode', {
  ...AllThemes.background.primary,
});

// header background colors - have opacity
export const headerBackgroundColor: theme.ThemeSet = theme('mode', {
  ...AllThemes.background.header,
});

// side menu background color
export const sideMenuBackgroundColor: theme.ThemeSet = theme('mode', {
  ...AllThemes.background.menu,
});


/* Text Colors */

// default text color
export const textColor: theme.ThemeSet = theme('mode', {
  ...AllThemes.text.primary,
});

// label color
export const labelColor: theme.ThemeSet = theme('mode', {
  ...AllThemes.text.label,
});

// tag color - hashtag styles
export const tagColor: theme.ThemeSet = theme('mode', {
  ...AllThemes.text.tag,
});


/* Border Colors */

// default border color
export const borderColor: theme.ThemeSet = theme('mode', {
  ...AllThemes.border.primary,
});


/* Placeholders and Loaders */

export const placeholderBackgroundColor: theme.ThemeSet = theme('mode', {
  ...AllThemes.background.secondary,
});


/* Button Colors */

// default button text color
export const buttonTextColor: theme.ThemeSet = theme('mode', {
  ...AllThemes.buttons.text.default,
});

// button hover color
export const buttonHoverTextColor: theme.ThemeSet = theme('mode', {
  ...AllThemes.buttons.text.hover,
});

// button hover background color
export const buttonHoverBackgroundColor: theme.ThemeSet = theme('mode', {
  ...AllThemes.buttons.background.hover,
});

// button active background color
export const buttonActiveBackgroundColor: theme.ThemeSet = theme('mode', {
  ...AllThemes.buttons.background.active,
});

// button selected (e.g. current page / tab) background color
export const buttonSelectedBackgroundColor: theme.ThemeSet = theme('mode', {
  ...AllThemes.buttons.background.selected,
});

/* Modal Colors */

// default modal background color
export const modalBackgroundColor: theme.ThemeSet = theme('mode', {
  ...AllThemes.background.modal,
});