/* Default themes */

const v = (light, dark) => ({
  light: light,
  dark: dark,
});

export const defaultThemes = {
  background: {
    primary: v('#fafafa', '#0e0f11'),
    secondary: v('#eee', '#333'),
    header: v('rgba(250,250,250,0.97)', 'rgba(16,16,16,0.97)'),
    menu: v('#fcfcfc', '#0d0d0d'),
    modal: v('#fff', '#030303'),
  },
  border: {
    primary: v('#e6e6e6', '#333')
  },
  buttons: {
    text: {
      default: v('#435264', '#dde2e7'),
      hover: v('#000', '#fff')
    },
    background: {
      hover: v('#e6e6e6', '#3c3c3c'),
      active: v('#fff', '#1a1a1a'),
      selected: v('rgba(255,255,255,0.8)', 'rgba(26,26,26,0.8)')
    }
  },
  preloader: {
    foregrounnd: v('#f1f1f1', '#181818'),
    background: v('#e1e1e1', '#333333')
  },
  text: {
    primary: v('#191919', '#fafafa'),
    label: v('#444', '#ccc'),
    tag: v('#999', '#999'),
  },
};

export default defaultThemes;