import React from 'react';
import { Link } from 'react-router-dom';
import { ThemeToggle } from '../../library/ThemeToggle';
import { JKRBLogo, MenuIcon, BTCIcon, DOTIcon, ETHIcon } from '../../library/SVG';
import { AllThemes } from '../../theme/all';
import { useTheme } from '../../library/ThemeManager';
import { ButtonPrimary } from '../../library/ButtonPrimary';
import { Logo } from './Logo';
import { Wrapper } from './Wrapper';
import { useLocation } from 'react-router-dom';
import { useMenu } from '../../library/MenuContext';
import { MENU_LINKS } from '../../constants';

export const NavBar = (props: any) => {

  // theme provider
  const theme = useTheme();

  // menu context
  const menu = useMenu();

  // get current page user is on
  const { pathname } = useLocation();

  // local header state is passed from index
  const { headerState } = props;

  const headerBackgroundColors = AllThemes.background.header;
  const buttonTextColors = AllThemes.buttons.text.default;

  return (
    <Wrapper
      headerState={headerState}
      backgroundColor={headerBackgroundColors[theme.mode]}
      buttonTextColor={buttonTextColors[theme.mode]}
      playTransition={
        (theme.count > 0 &&
          theme.transitionTheme === 1)
          ? true
          : false
      }
      theme={theme.mode}
      showHeader={(headerState.show && headerState.initialised)}
      hideHeader={(!headerState.show && headerState.initialised)}
    >
      <Logo>
        <Link to="/" className='logo'>
          <JKRBLogo />
        </Link>
        {theme.mode !== 'light' && theme.mode !== 'dark' &&
          <div className='icon'>
            {theme.mode === 'btc' && <BTCIcon />}
            {theme.mode === 'dot' && <DOTIcon />}
            {theme.mode === 'eth' && <ETHIcon />}
          </div>
        }
      </Logo>

      <div className='bar'>
        <div className='links'>
          <div className='hide-mobile'>
            {MENU_LINKS.map((item, index) => {
              if (item.active) {
                return (
                  <React.Fragment key={index}>
                    <Link to={item.path}>
                      <ButtonPrimary label={item.label} selected={pathname === item.path} />
                    </Link>
                  </React.Fragment>
                );
              } else {
                return <React.Fragment key={index} />
              }
            })}
          </div>
        </div>
        <ThemeToggle />
        <div className='menu show-mobile' onClick={() => menu.toggleSideMenu()}>
          <MenuIcon />
        </div>
      </div>

    </Wrapper>
  );
}

export default NavBar;