import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import { labelColor, placeholderBackgroundColor } from '../../../theme/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin, faMedium, faGithub } from '@fortawesome/free-brands-svg-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Anchor, Section, SubSection } from '../../../library/StyledComponents';

const StyledFounders = styled.div<any>`
  max-width: 100%;
  overflow: hidden;

  > div {
    width: 100%;
    h1 {
      margin-top: 0;
    }
  }

  article {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    padding: 2rem 0 2.5rem 1rem;
    @media(max-width: 850px) {
      padding-left: 0;
    }

    > div {
      &.left {
        width: ${props => props.imgSize};
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
        align-content: flex-start;

        @media(max-width: 850px) {
          flex-basis: 100%;
          margin-left: 1rem;
        }
        > div {
          width: 100%;
          flex-grow: 0;
          text-align: left;
          h2, h3 {
            margin: 0.3rem 0;
            text-align: center;

            @media(max-width: 850px) {
              text-align: left;
            }
          }
        }
        .img {
          width: ${props => props.imgSize};
          height: $${props => props.imgSize};
          position: relative;
          margin-bottom: 0.75rem;
          .ph {
            width: 100%;
            height: 100%;
            max-width: ${props => props.imgSize};
            max-height: ${props => props.imgSize};
            padding-bottom: 100%;
            background: ${placeholderBackgroundColor};
            border-radius: 50%;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 0;
          }
          img {
            width: 100%;
            border-radius: 50%;
            z-index: 1;
            position: relative;
          }
        }            
      }

      &.right {
        flex: 1;
        margin-left: 1.5rem;
        @media(max-width: 850px) {
          padding-left: 0;
          margin-left: 0;
        }
        @media(max-width: 600px) {
          margin-left: 0;
          padding: 0;
        }
        display: flex;
        flex-flow: row wrap;
        > div:first-child {
          order: 0;
        }
        > p {
          order: 1;
        }
        @media(max-width: 850px) {
          > div:first-child {
            order: 1;
          }
          > p {
            order: 0;
          }
        }

        p.socials {
          margin-top: 0.8rem;
          margin-bottom: 0.8rem;
          font-size: 1.75rem;
          a {
            color: ${labelColor};
          }
          svg {
            margin-right: 1.5rem;
          }
        }
      }
    }
  }
`;

export const Founders: React.FC = () => {

  const imgSize = '140px';

  return (
    <Section>
      <Anchor>
        <Element name="founders"></Element>
      </Anchor>
      <SubSection>
        <StyledFounders imgSize={imgSize}>
          <div className='founders'>
            <div>
              <h1>Founders</h1>
              <article>
                <div className='left'>
                  <div className='img'>
                    <LazyLoadImage
                      src="/img/joelKenny.jpg"
                      alt="Ross Bulat"
                      threshold="500"
                    />
                    <span className='ph'></span>
                  </div>
                  <div>
                    <h2>Joel Kenny</h2>
                    <h3>Director</h3>
                  </div>
                </div>

                <div className='right'>
                  <div>
                    <p>From an early age, Joel has always had a passion for business ventures. Having worked in a variety of sectors, Joel applies previous experience alongside a strong interest in new technology to strengthen JKRB offerings.</p>
                    <p>Joel manages numerous JKRB assets and projects, maximising potential, profitability and efficiency along with researching new investment opportunities. With a strong interest in the economic workings of digital assets and the technical opportunities Blockchain technology offers.</p>
                  </div>
                  <p className='socials'>
                    <a
                      href="https://medium.com/@joelkenny"
                      target="_blank"
                      rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faMedium} />
                    </a>
                    <a
                      href="https://www.twitter.com/joeljkrb"
                      target="_blank"
                      rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/joel-kenny/"
                      target="_blank"
                      rel="noopener noreferrer" >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </p>
                </div>
              </article>
              <article>
                <div className='left'>
                  <div className='img'>
                    <LazyLoadImage
                      src="/img/rossBulat.jpg"
                      alt="Ross Bulat"
                      threshold="500"
                    />
                    <span className='ph'></span>
                  </div>
                  <div>
                    <h2>Ross Bulat</h2>
                    <h3>Director</h3>
                  </div>
                </div>
                <div className='right'>
                  <div>
                    <p>Having had entrepreneurial and technical exposure from a young age, Ross uses his experience alongside his business acumen to develop new ideas.</p>
                    <p>As a full stack web developer, Ross contributes and directs the development of the online presence of JKRB, and is heavily involved in all of JKRB online projects. Ross researches Blockchain technology and the economics around cryptocurrencies. He writes smart contracts for the Ethereum platform, and plays an advisory role for a range of start-ups in the field.</p>
                    <p>Ross also researches emerging technologies including automation and machine learning, and studies mathematics around this field.</p>
                  </div>

                  <p className='socials'>
                    <a
                      href="https://medium.com/@rossbulat"
                      target="_blank"
                      rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faMedium} />
                    </a>
                    <a
                      href="https://twitter.com/rossbulat"
                      target="_blank"
                      rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a
                      href="https://github.com/rossbulat"
                      target="_blank"
                      rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faGithub} />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/rossbulat/"
                      target="_blank"
                      rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </p>
                </div>
              </article>
            </div>
          </div>
        </StyledFounders>
      </SubSection>
    </Section>
  );
}

export default Founders;