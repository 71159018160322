import React from 'react';
import styled from 'styled-components';
import { labelColor, buttonHoverTextColor } from '../../theme/theme';
import { containerWidths } from '../../constants';

const Wrapper = styled.div<any>`
  align-self: flex-end;
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  width: 100%;
  text-align: center;
  flex: 0;
  width: 100%;
  padding: 1.5rem;
  font-weight: bold;
  font-size: 0.9em;
  color: ${labelColor};

  > div {
    flex: 1;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    @media(max-width: ${containerWidths.mobile}px) {
      flex-basis: 100%;
      justify-content: center;
      padding: 1rem;
    }
  }

  > label {
    cursor: pointer;
    flex: 1;
    display: flex;
    align-self: flex-end;
    height: inherit;
    align-items: center;
    justify-content: flex-end;
    min-width: 200px;

    @media(hover: hover) and (pointer: fine) {
      transition: color 0.15s;
      &:hover {
        color: ${buttonHoverTextColor};
      }
    }

    @media(max-width: ${containerWidths.mobile}px) {
      flex-basis: 100%;
      justify-content: center;
    }
  }
`;

export const Footer: React.FC = () => {

  const year = (new Date()).getFullYear();

  return (
    <Wrapper>
      <div>
        &copy; JKRB.io 2017 - {year}
      </div>
    </Wrapper>
  );
}