import React from 'react';
import { ReactComponent as SVGMoonCurve } from '../../img/MoonCurve.svg';
import { ReactComponent as SVGSunMain } from '../../img/SunMain.svg';
import { ReactComponent as SVGSunMin } from '../../img/SunMin.svg';
import { ReactComponent as SVGSunShine } from '../../img/SunShine.svg';
import { ReactComponent as SVGJKRBLogo } from '../../img/logo.svg';
import { ReactComponent as SVGCMPC } from '../../img/cmpc.svg';
import { ReactComponent as SVGEthereum } from '../../img/ethereum.svg';
import { ReactComponent as SVGWeb3 } from '../../img/web3.svg';
import { ReactComponent as SVGMedium } from '../../img/medium.svg';
import { ReactComponent as SVGEmberr } from '../../img/emberr.svg';
import { ReactComponent as SVGMenu } from '../../img/menu.svg';
import { ReactComponent as SVGBTC } from '../../img/btc.svg';
import { ReactComponent as SVGDOT } from '../../img/dot.svg';
import { ReactComponent as SVGETH } from '../../img/eth.svg';


export const MoonCurve: React.FC = () => <SVGMoonCurve />;

export const SunMain: React.FC = () => <SVGSunMain />;

export const SunMin: React.FC = () => <SVGSunMin />;

export const SunShine: React.FC = () => <SVGSunShine />;

export const JKRBLogo: React.FC = () => <SVGJKRBLogo />;

export const CMPCLogo: React.FC = () => <SVGCMPC />;

export const EthereumLogo: React.FC = () => <SVGEthereum />;

export const Web3Logo: React.FC = () => <SVGWeb3 />;

export const MediumLogo: React.FC = () => <SVGMedium />;

export const EmberrLogo: React.FC = () => <SVGEmberr />;

export const MenuIcon: React.FC = () => <SVGMenu />;

export const BTCIcon: React.FC = () => <SVGBTC />;

export const DOTIcon: React.FC = () => <SVGDOT />;

export const ETHIcon: React.FC = () => <SVGETH />;