import styled from 'styled-components';
import { containerWidths } from '../../constants';
import { HeaderWrapper } from '../../types';

export const Wrapper = styled.div<HeaderWrapper & any>`
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap;
  height: 75px;
  justify-content: center;
  padding: 0 0.75rem;
  position: fixed;
  left: 0px;
  width: 100%;
  transition: all 0.2s;
  z-index: 3;

  @keyframes show_header {
    from { top: -75px; }
    to { top: 0px; }
  }

  @keyframes hide_header {
    0% { top: 0px; }
    50% { top: 0px; }
    100% { top: -75px; }
  }
  background-color: ${props => props.backgroundColor};
  
  ${props => props.headerState.show === true
    ? `top: 0px;`
    : `top: -75px;`
  }

  ${props => props.showHeader && ` 
    animation-fill-mode: forwards;
    animation: show_header 0.4s;`
  }
  
  ${props => props.hideHeader && `
    animation-name: hide_header;
    animation-duration: 1s;
    animation-fill-mode: forwards;`
  }
  
  @media(min-width: ${containerWidths.mobile + 1}px) {
    padding: 0rem 1.5rem 0 1.5rem;
  }

  /* smaller header on mobile */
  @media(max-width: ${containerWidths.mobile}px) {
    height: 68px;
  }

  .bar, .links {
    display: flex;
    align-items: center;
    align-self: flex-end;
  }

  .links {
    flex-grow: 1;
  }

  .links > button {
      margin-right: 0.5em;
      @media(max-width: ${containerWidths.mobile}px) {
        margin-right: 0.25em;
      }
    }

  .menu {
    cursor: pointer;
    display: inline-block;
    height: 2rem;
    position: relative;
    width: 2rem;
    margin-left: 1.25rem;
    max-height: 40px;

    svg {
      width: 100%;
      height: 100%;
      padding: 0.5rem 0;
      fill: ${props => props.buttonTextColor};

      path, g {
        fill: ${props => props.buttonTextColor};
      }
    }
  }
`;

export default Wrapper;