import React from "react";

export const BlogContext: React.Context<any> = React.createContext({
  list: [],
  loaded: false,
  populateList: () => { }
});

export const useBlog = () => React.useContext(BlogContext);

export class ContextBlog extends React.Component<any, any> {

  public state = {
    loaded: false,
    list: [],
  };

  public populateList = async (articles) => {

    if (articles === undefined || !Number.isInteger(articles.length)) {
      this.setState({ loaded: true });
      return false;
    }
    this.setState({ loaded: true, list: articles });
  }

  public render () {
    return (
      <BlogContext.Provider value={{
        loaded: this.state.loaded,
        list: this.state.list,
        populateList: this.populateList
      }}>
        {this.props.children}
      </BlogContext.Provider>
    );
  }
}

export default ContextBlog;

