import styled from 'styled-components'
import { containerPadding, containerWidth, containerWidths } from '../../constants';
import { textColor, placeholderBackgroundColor, buttonHoverBackgroundColor, labelColor } from '../../theme/theme';


/* Section
 * the main container for a page section
 * this by default consumes the window height
 */
export const Section = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  position: relative;

  /* container padding at different window sizes */
  padding: 60px ${containerPadding.mobile} 0;

  @media(min-width: ${containerWidths.mobile + 1}px) {
    padding: 60px ${containerPadding.tablet} 0;
  }

  @media(min-width: ${containerWidths.desktop}px) {
    padding: 60px ${containerPadding.desktop};
  }
`;


/* SubSection
 * a wrapper for content inside a Section
 * this is centered vertically within Section
 */
export const SubSection = styled.div`
  display: flex;
  flex: 1;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column wrap;
  width: 100%;
  max-width: ${containerWidth.desktop};
  word-wrap: break-word;
  position: relative;
`;


/* ButtonRow
 * used to horizontally align a list of buttons
 * commonly below a subtitle of a page
 */
export const ButtonRow = styled.div`
  margin: 1rem;

  button {
    margin: 0em 0.6em 0.75rem 0;
    font-size: 0.9rem;
    
    &:first-child {
      margin-left: 0;
    }
  }
`;


/* CiclePrimary
 * circular button used to interact with site features
 * copy content, go to email client, etc
 */
export const CirclePrimary = styled.button<any>`
  background-color: ${placeholderBackgroundColor};
  font-size: 1.2rem;
  width: 2.8rem;
  height: 2.8rem;
  margin: 0;
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  margin-right: 0.5rem;
  color: ${textColor};
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  
  /* shrink on snaller devices */
  @media(max-width: ${containerWidth.mobile}) {
    font-size: 0.95rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  /* hover color where device has pointer */
  @media(hover: hover) and (pointer: fine) {
    transition: all 0.15s;
    &:hover {
      background-color: ${buttonHoverBackgroundColor};
    }
  }
`;


/* Anchor
 * used to position react-scroll Elements
 * to the top of the section in question 
 */
export const Anchor = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
`;


/* BlogWrapper
 * wrapper element for JKRB blog interface
 * used in blog lists and article pages
 */
export const BlogWrapper = styled.div`
  margin-top: 3rem;
  width: 100%;

  article {
    width: 100%;

    .category {
      color: ${labelColor};
      text-transform: uppercase;
      margin-bottom: 0.9rem;
      font-weight: 500;
      opacity: 0.75;
    }
    
    .head {
      margin-bottom: 2rem;
      width: 100%;
      .title {
        margin-top: 0rem;
        margin-bottom: 0.4rem;
      }
      .subtitle {
        margin-top: 0.55rem;
        font-weight: 700;
      }
    }
    .separator {
      width: 100%;
      border-bottom: 1px solid #ddd;
      margin: 2.5rem 0;
    }
  }
`;
