import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useTheme } from './library/ThemeManager';
import { Header } from './pages/Header';
import { Footer } from './pages/Footer';
import Home from './pages/Home';
import Code from './pages/Code';
import Article from './pages/Blog/Article';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Wrapper } from './Wrapper';
import ScrollToTop from './library/ScrollToTop';
import SideMenu from './library/SideMenu';
import ContextMenu from './library/MenuContext';
import ContextBlog from './library/BlogContext';

const App: React.FC = () => {

  const theme = useTheme();

  return (
    <ThemeProvider theme={{ mode: theme.mode }}>
      <ContextMenu>
        <ContextBlog>
          <BrowserRouter>
            <Wrapper>
              <ScrollToTop />
              <SideMenu />
              <Header />
              <Routes>
                <Route path="/blog/read/:slug" element={<Article />} />
                <Route path="/code" element={<Code />} />
                <Route path="/" element={<Home />} />
              </Routes>
              <Footer />
            </Wrapper>
          </BrowserRouter>
        </ContextBlog>
      </ContextMenu>
    </ThemeProvider>
  );
}

export default App;