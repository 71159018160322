/* Cryptocurrency themes */

const v = (btc, dot, eth) => ({
  btc: btc,
  dot: dot,
  eth: eth,
});

export const cryptoThemes = {
  background: {
    primary: v('#fafafa', '#fafafa', '#fafafa'),
    secondary: v('#eee', '#eee', '#eee'),
    header: v('rgba(250,250,250,0.97)', 'rgba(250,250,250,0.97)', 'rgba(250,250,250,0.97)'),
    menu: v('#fcfcfc', '#fcfcfc', '#fcfcfc'),
    modal: v('#fff', '#fff', '#fff'),
  },
  border: {
    primary: v('#e6e6e6', '#e6e6e6', '#e6e6e6')
  },
  buttons: {
    text: {
      default: v('#ea973d', '#d13079', '#6680e3'),
      hover: v('#533718', '#551c36', '#1f284c')
    },
    background: {
      hover: v('#e6e6e6', '#e6e6e6', '#e6e6e6'),
      active: v('#fff', '#fff', '#fff'),
      selected: v('rgba(255,255,255,0.8)', 'rgba(255,255,255,0.8)', 'rgba(255,255,255,0.8)')
    }
  },
  preloader: {
    foregrounnd: v('#f1f1f1', '#f1f1f1', '#f1f1f1'),
    background: v('#e1e1e1', '#e1e1e1', '#e1e1e1')
  },
  text: {
    primary: v('#191919', '#111', '#333'),
    label: v('#333', '#666', '#333'),
    tag: v('#999', '#999', '#999'),
  },
};

export default cryptoThemes;