import styled from 'styled-components';
import { modalBackgroundColor } from '../../theme/theme';

export const Wrapper = styled.div<any>`
width: 100%;
z-index: 5;
position: fixed;
bottom: -100%;
left: 0px;

@keyframes notifier-open {
  from { bottom: -100px }
  to { bottom: 0px }
}

@keyframes notifier-close {
  from { bottom: 0px }
  to { bottom: -100px }
}

&.notifier-open {
  animation: notifier-open cubic-bezier(0.23, 1, 0.32, 1) 1;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-delay: 0s;
}

&.notifier-close {
  animation: notifier-close cubic-bezier(0.23, 1, 0.32, 1) 1;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-delay: 0s;
}

> div {
  padding: 10px;
  background-color: ${modalBackgroundColor};
  text-align: center;
  margin: 5px 10px;
  border-radius: 10px;
}
`;

export default Wrapper;